<template>
    <div class="content-section row" v-if="this.dataSet.total>0">
        <div class="col-12 title-wrapper">
            <h4 class="search-results__title" @click="collapseContent = !collapseContent"><span class="title">{{title}}</span><span class="material-icons-outlined" aria-hidden="true">{{!collapseContent ? 'expand_less' : 'expand_more'}}</span></h4>
        </div>

        <div class="col-12">
            <div v-if="!collapseContent" class="row custom-row">
                <div v-for="(item, index) in dataSet.data" :key="`key${index}`" class="col-12 col-sm-6 col-xl-4">
                    <angebot-card v-if="item.content_type_name=='angebot'" :openInNewTab="openInNewTab" :item="item" :autoWidth="true"/>
                    <ort-card v-if="item.content_type_name=='institution'" :openInNewTab="openInNewTab" :item="item" :autoWidth="true"/>
                    <ratgeber-card v-if="item.content_type_name=='ratgeberartikel'" :openInNewTab="openInNewTab" :content="item" :autoWidth="true"/>
                    <bildung-card v-if="item.content_type_name=='schule' || item.content_type_name=='kita'" :autoWidth="true" :openInNewTab="openInNewTab" :item="item"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getContentsOfType } from '@/api/content';
import { showLoader, hideLoader, isset } from '@/utils/helpers';

export default {
    name: "ContentSection",
    components: {
        OrtCard: () => import('@/components/cards/Ort.vue'),
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        BildungCard: () => import('@/components/cards/Bildung.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
    },
    props: {
        title: {
            type: String,
            required: true
        },
        contentId: {
            type: Number,
            required: true
        },
        searchWord: {
            type: String,
            required: true
        },
        openInNewTab: {
            type: Boolean,
        default: false,
        }
    },
    data() {
      return {
        collapseContent: false,
        dataSet: {
          total: 0,
          data: [],
          meta:null,
          query: {
            page:1,
            type: "view",
            limit: 50,
            keyword: '',
                //selectedAppId: this.$appId,
            view_status: 1,
            selectRandomly: true,
        },
    },
}
},
created() {
    this.getContents();
},
computed: {

},
methods:{
    showLoader,
    hideLoader,
    getContents() {
        this.dataSet.query.keyword = this.searchWord;
        if(this.dataSet.query.keyword==="" || this.dataSet.query.keyword.length<3){
            return;
        }
        var contentLoader = this.showLoader(contentLoader);
        const { limit, page } = this.dataSet.query;
        getContentsOfType(this.contentId,this.dataSet.query).then(response => {
            const { data, meta } = response;
            this.dataSet.data = data;
            this.dataSet.data.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1;
          });
            this.dataSet.meta = meta;
            this.dataSet.total = meta.total;
            this.$emit('numberOfResults',meta.total);
        })
        .finally(() => {
            contentLoader = this.hideLoader(contentLoader);
        });
    }, 
}
}
</script>
<style lang="scss" scoped>
.content-section{
    margin-bottom: 40px;

    .custom-row {
        .col-xl-4 {
            @media (max-width: 1460px) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (max-width: 650px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .title-wrapper {
        @media (max-width: 650px) {
            padding-left: 0px;
            padding-right: 0px;
        }

        .search-results__title {
            display:flex;
            cursor: pointer;

        }

        .title {
            display: flex;
            align-items: center;
        }
    }      
}
</style>